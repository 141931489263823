<template>
	<div class="activity-feed">
		<v-card flat :min-height="layout && layout === 'blank' ? 630 : 415">
			<v-card-text class="pb-0">
				<div class="d-flex flex-wrap justify-space-between align-center">
					<h2 class="fs--23 fw--500">Activity Feed</h2>
					<div class="d-flex align-center flex-wrap">
						<DateRange :range="date.range" @onDateRangeChange="actions.onDateChange" />

						<v-menu offset-y :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="secondary"
									class="px-0 ml-2 mt-1 mt-sm-0"
									small
									dark
									v-bind="attrs"
									v-on="on">
									<template #default>
										<div class="d-flex align-center">
											<div class="text-center px-5 px-lg-5 px-xl-10">
												<span>Filter</span>
											</div>

											<v-divider vertical class="mx-0 mx-sm-2"></v-divider>
											<v-icon class="mr-2">
												{{ icons.arrow }}
											</v-icon>
										</div>
									</template>
								</v-btn>
							</template>
							<v-list dense color="secondary" class="pt-0 pb-4 px-0">
								<v-list-item>
									<v-checkbox
										v-model="filter.custom"
										@change="actions.onFilterChange"
										label="Custom"
										color="textColor"
										:value="StickerType.CUSTOM"
										hide-details></v-checkbox>
								</v-list-item>
								<v-list-item>
									<v-checkbox
										v-model="filter.reward"
										@change="actions.onFilterChange"
										label="Reward"
										color="textColor"
										:value="StickerType.REWARD"
										hide-details></v-checkbox>
								</v-list-item>
								<v-list-item>
									<v-checkbox
										v-model="filter.tts"
										@change="actions.onFilterChange"
										label="TTS"
										color="textColor"
										:value="StickerType.TTS"
										hide-details></v-checkbox>
								</v-list-item>
								<v-list-item>
									<v-checkbox
										v-model="filter.global"
										@change="actions.onFilterChange"
										label="Global"
										color="textColor"
										:value="StickerType.GLOBAL"
										hide-details></v-checkbox>
								</v-list-item>
							</v-list>
						</v-menu>
						<v-icon v-if="layout !== 'blank'" class="ml-2" @click="actions.openWindow">
							{{ icons.newWindow }}
						</v-icon>
					</div>
				</div>

				<v-data-table
					class="activity-feed-table mt-2"
					:headers="headers"
					:items="data.items"
					show-expand
					single-expand
					:expanded.sync="expanded.items"
					:items-per-page="layout && layout === 'blank' ? 10 : 5"
					:hide-default-footer="data.items.length < 1">
					<template v-slot:[`item.date`]="{ item }">
						<span class="fs--14 fw--600 warning--text">{{ item.date }}</span>
					</template>
					<template v-slot:[`item.user`]="{ item }">
						<div class="d-flex align-center">
							<v-avatar size="30">
								<img
									:src="actions.profilePic(item.profilePic)"
									@error="$event.target.src = `${actions.defaultProfilePic()}`" />
							</v-avatar>
							<span class="fs--14 pl-2">{{ item.user }}</span>
						</div>
					</template>
					<template v-slot:[`item.image`]="{ item }">
						<div v-if="item.type != StickerType.TTS" class="sticker-image-container">
							<img
								:src="actions.getFullStickerImageUrl(item)"
								@error="$event.target.src = `${actions.defaultSticker()}`" />
						</div>
						<div v-else-if="item.tts && !item.tts.bounds" class="sticker-image-container">
							<img
								:src="actions.defaultSticker(item)"
								@error="$event.target.src = `${actions.defaultSticker()}`" />
						</div>
						<div
							v-else-if="item.type == StickerType.TTS && (!item.tts || !item.tts.bounds)"
							class="sticker-image-container">
							<img
								:src="actions.defaultSticker(item)"
								@error="$event.target.src = `${actions.defaultSticker()}`" />
						</div>
						<div
							v-else-if="item.tts && item.tts.bounds"
							class="base-image"
							:style="{
								backgroundImage: `url(${actions.getFullStickerImageUrl(item)}`,
								minHeight: '30px',
								minWidth: '25px',
								maxHeight: '30px',
								maxWidth: '25px',
								backgroundPosition: 'center center',
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
							}">
							<!-- Set Eye Image -->
							<div
								v-if="item.tts.isEyeEnabled && item.tts.eyeImage && item.tts.bounds"
								:style="{
									position: 'absolute',
									width: '100%',
									height: '100%',
								}">
								<div
									:style="{
										position: 'absolute',
										backgroundImage: `url(${actions.getImage(item, 'eyes', item.tts.eyeImage)})`,
										backgroundSize: 'contain',
										backgroundPosition: 'center center',
										zIndex: 4,
										transform: item.tts.isEyeFlipped
											? `rotate(${item.tts.position.eyeAngle}deg) scaleX(-1)`
											: `rotate(${item.tts.position.eyeAngle}deg) scaleX(1)`,
										left: `${(item.tts.bounds.eyeX / 250) * 100}%`,
										top: `${(item.tts.bounds.eyeY / 300) * 100}%`,
										width: `${(item.tts.bounds.eyeW / 100) * 10}px`,
										height: `${(item.tts.bounds.eyeH / 100) * 10}px`,
									}"></div>
							</div>

							<!-- Set Animated Mouth Image -->

							<div
								v-if="item.tts.isMouthEnabled && item.tts.mouthImage && item.tts.bounds"
								:style="{
									position: 'absolute',
									width: '100%',
									height: '100%',
								}">
								<div
									:style="{
										position: 'absolute',
										backgroundImage: `url(${actions.getImage(item, 'mouth', item.tts.mouthImage)})`,
										backgroundSize: 'contain',
										backgroundPosition: 'center center',
										zIndex: 4,
										transform: item.tts.isMouthFlipped
											? `rotate(${item.tts.position.mouthAngle}deg) scaleX(-1)`
											: `rotate(${item.tts.position.mouthAngle}deg) scaleX(1)`,
										left: `${(item.tts.bounds.mouthX / 250) * 100}%`,
										top: `${(item.tts.bounds.mouthY / 300) * 100}%`,
										width: `${(item.tts.bounds.mouthW / 100) * 10}px`,
										height: `${(item.tts.bounds.mouthH / 100) * 10}px`,
									}"></div>
							</div>
						</div>
					</template>
					<template v-slot:[`item.type`]="{ item }">
						{{ item.type }}
						<v-btn
							v-if="item.type === StickerType.TTS && item.ttsApprovalStatus == TtsApprovalQueueStatus.NEW"
							class="ml-2"
							x-small
							color="pink"
							depressed
							@click="actions.handleExpansion(item, isExpand)">
							Review
						</v-btn>

						<v-btn
							v-if="
								item.type === StickerType.TTS &&
								item.ttsApprovalStatus == TtsApprovalQueueStatus.APPROVED
							"
							class="ml-2"
							text
							x-small>
							<span class="success--text fs--11">Sent</span>
						</v-btn>

						<v-btn
							v-if="
								item.type === StickerType.TTS &&
								item.ttsApprovalStatus == TtsApprovalQueueStatus.REJECTED
							"
							class="ml-2 error--text"
							text
							x-small
							color="error">
							<span class="fs--11">Denied</span>
						</v-btn>
						<v-btn
							v-if="
								item.type === StickerType.TTS &&
								item.ttsApprovalStatus == TtsApprovalQueueStatus.EXPIRED
							"
							class="ml-2 error--text"
							text
							x-small
							color="error">
							<span class="fs--11">Expired</span>
						</v-btn>
					</template>
					<template v-slot:[`item.bits`]="{ item }">
						<div class="d-flex align-center">
							<img class="" width="20" :src="getBitIcon(item.bits)" />
							<span class="fs--14 pl-2">{{ Number(item.bits).toLocaleString() }}</span>
						</div>
					</template>
					<template v-slot:[`item.action`]="{ item }">
						<v-icon
							v-if="
								data.streamerOnline &&
								(!item.ttsApprovalStatus || item.ttsApprovalStatus == TtsApprovalQueueStatus.APPROVED)
							"
							@click="actions.replayStickerSlap(item.id)"
							size="26"
							color="accent">
							{{ icons.sync }}
						</v-icon>
					</template>
					<template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
						<template v-if="item.type === StickerType.TTS">
							<v-icon @click.stop="actions.handleExpansion(item, isExpanded)">
								{{ isExpanded ? icons.arrow : icons.arrowUp }}
							</v-icon>
						</template>
					</template>
					<template v-slot:expanded-item="{ item }">
						<td v-if="item.type === StickerType.TTS" :colspan="headers.length">
							<v-row align="center">
								<v-col cols="12" :sm="item.ttsApprovalStatus != TtsApprovalQueueStatus.NEW ? 12 : 8">
									<span
										v-if="item.ttsApprovalStatus == TtsApprovalQueueStatus.NEW"
										class="fieldFore--text fs--12">
										Here is the text that the user sent with the TTS Sticker. It must be manually
										approved. "{{ item.ttsText }}"
									</span>
									<span v-else class="fieldFore--text fs--12">
										Here is the text that the user sent with the TTS Sticker. "{{ item.ttsText }}"
									</span>
								</v-col>
								<v-col
									v-if="item.ttsApprovalStatus == TtsApprovalQueueStatus.NEW"
									:cols="12"
									sm="4"
									class="text-right pt-1 mb-2 mb-sm-0">
									<v-btn
										depressed
										x-small
										color="success"
										class="mr-1"
										@click="actions.updateStatus(item, TtsApprovalQueueStatus.APPROVED)">
										Approve
									</v-btn>
									<v-btn
										depressed
										x-small
										color="error"
										@click="actions.updateStatus(item, TtsApprovalQueueStatus.REJECTED)">
										Deny
									</v-btn>
								</v-col>
							</v-row>
						</td>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import axios from '@axios';
import moment from 'moment';
import { reactive, ref } from '@vue/composition-api';
import { getGlobalStickerAssetPath, getCustomStickerAssetPath, getHostedAssetPath } from '../../../utils/config';
import { StickerType, TtsApprovalQueueStatus } from '../../../utils/constants';
import { getBitIcon } from '../../../utils/utils';
import { getUserId } from '../../../utils/auth';
import { mdiSync, mdiChevronDown, mdiChevronUp, mdiOpenInNew } from '@mdi/js';
import DateRange from '@/views/shared/DateRange.vue';
export default {
	components: {
		DateRange,
	},
	props: {
		layout: {
			type: String,
			default: 'content',
		},
	},
	setup() {
		const expanded = reactive({ items: [] });
		const isExpand = ref(false);
		const headers = [
			{
				text: 'DATE',
				align: 'start',
				sortable: true,
				value: 'formattedDate',
			},
			{ text: 'STICKER', value: 'image', sortable: false, filterable: false, align: 'center' },
			{ text: 'TYPE', value: 'type' },
			{ text: 'USER', value: 'user' },
			{ text: 'BITS', value: 'bits' },
			{ text: '', value: 'action', sortable: false, filterable: false },
		];

		const date = reactive({
			range: [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
		});

		const data = reactive({
			items: [],
			isStreamerOnline: false,
		});

		const filter = reactive({
			custom: StickerType.CUSTOM,
			reward: StickerType.REWARD,
			tts: StickerType.TTS,
			global: StickerType.GLOBAL,
		});

		const isPresetFile = file => {
			return file.indexOf('/') < 0;
		};

		const actions = {
			handleExpansion(item, state) {
				// Make sure to replace the id as stickerId or whatever unique key you have in your sticker object to make it work properly.
				if (expanded.items.length === 1 && expanded.items[0].id === item.id && state) {
					expanded.items = [];
					isExpand.value = false;
					return false;
				} else {
					expanded.items = [];
					expanded.items.push(item);
					isExpand.value = true;
					return true;
				}
			},
			onDateChange: function (item) {
				date.range = [];
				date.range.push(...item);

				actions.fetchData();
			},
			onFilterChange: function () {
				actions.fetchData();
			},

			fetchData: function () {
				const filters = [];
				if (filter.custom) filters.push(filter.custom);
				if (filter.reward) filters.push(filter.reward);
				if (filter.tts) filters.push(filter.tts);
				if (filter.global) filters.push(filter.global);

				const filterString = filters.join(',');

				axios
					.get(`/dashboard/activity-feed/${date.range[0]}/${date.range[1]}?filters=${filterString}`)
					.then(res => {
						Object.assign(data, res.data);

						// Format epoch as string.
						for (let item of data.items) {
							item.formattedDate = moment(item.date).format('MM/DD/YYYY');
						}
					});
			},

			updateStatus(item, status) {
				axios.post(`/dashboard/activity-feed/status/${item.id}/${status}`).then(res => {
					actions.fetchData();
				});
			},

			replayStickerSlap: function (transactionId) {
				axios.post(`/dashboard/activity-feed/replay-sticker-slap/${transactionId}`);
			},

			getFullStickerImageUrl: function (item) {
				const imagePath = item.iconView;

				if (item.type == StickerType.GLOBAL) {
					return getGlobalStickerAssetPath() + '/' + imagePath;
				} else if (item.type == StickerType.TTS && !item.isGlobalTts) {
					return getHostedAssetPath() + '/' + item.tts.baseImage;
				} else if (item.type == StickerType.TTS && item.isGlobalTts) {
					return getGlobalStickerAssetPath() + '/' + item.tts.baseImage;
				} else {
					return getCustomStickerAssetPath(getUserId()) + '/' + imagePath;
				}
			},

			getImage(item, type, image) {
				if (type == 'eyes') {
					if (!item.isGlobalTts) {
						return getHostedAssetPath() + '/' + image;
					} else {
						if (isPresetFile(image)) {
							return getGlobalStickerAssetPath() + '/tts/eyes/' + image;
						} else {
							return getGlobalStickerAssetPath() + '/' + image;
						}
					}
				} else if (type == 'mouth') {
					if (!item.isGlobalTts) {
						return getHostedAssetPath() + '/' + image;
					} else {
						if (isPresetFile(image)) {
							return getGlobalStickerAssetPath() + '/tts/mouth/animated/' + image;
						} else {
							return getGlobalStickerAssetPath() + '/' + image;
						}
					}
				}

				return null;
			},

			getClosedMouth(item) {
				if (!item.isGlobalTts) {
					return getHostedAssetPath() + '/' + item.tts.closedMouthImage;
				} else {
					if (item.tts.closedMouthImage) {
						return getGlobalStickerAssetPath() + '/' + item.tts.closedMouthImage;
					} else if (isPresetFile(item.tts.mouthImage)) {
						return getGlobalStickerAssetPath() + '/tts/mouth/closed/' + item.tts.mouthImage;
					}
				}
			},

			defaultSticker() {
				return require('@/assets/icons/sidebar/Stickers.png');
			},

			defaultProfilePic: function () {
				return require('@/assets/images/misc/profile.png');
			},

			profilePic: function (profilePic) {
				if (profilePic) {
					return profilePic;
				} else {
					return actions.defaultProfilePic;
				}
			},

			openWindow: () => {
				const url = window.location.origin + '/activity-dashboard';
				window.open(url, '', 'width=675, height=680, popup=yes');
			},
		};

		actions.fetchData();

		return {
			expanded,
			isExpand,
			date,
			headers,
			data,
			getBitIcon,
			filter,
			actions,
			StickerType,
			TtsApprovalQueueStatus,
			icons: {
				sync: mdiSync,
				arrow: mdiChevronDown,
				arrowUp: mdiChevronUp,
				newWindow: mdiOpenInNew,
			},
		};
	},
};
</script>
<style lang="scss" scoped>
.sticker-image-container {
	img {
		max-height: 30px;
	}
	margin: auto;
}

.base-image {
	position: relative;
	margin: auto;
}
</style>
