<template>
    <v-menu class="date-picker-menu" :min-width="$vuetify.breakpoint.xsOnly ? 290 : 390"
        :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn depressed color="primary" outlined class="px-0 mt-2 mt-sm-0" small dark
                :block="$vuetify.breakpoint.xsOnly && block" v-bind="attrs" v-on="on">
                <template #default>
                    <div class="d-flex align-center">
                        <div class="text-center px-2">
                            <v-icon color="textColor" size="17" class="mr-2">{{ calendar }}</v-icon>
                            <span class="textColor--text text-capitalize">{{ parseDate(date.range[0]) }} - {{
                                parseDate(date.range[1])
                            }}</span>
                        </div>
                    </div>
                </template>
            </v-btn>
        </template>
        <template v-slot:default>
            <v-card class="date-picker-card"
                :width="$vuetify.breakpoint.xsOnly ? 290 : $vuetify.breakpoint.mdAndDown ? 490 : 790">
                <div class="d-flex">
                    <template v-if="$vuetify.breakpoint.lgAndUp">
                        <div class="text-center purple pt-2">
                            <span class="offwhite--text">Start Date</span>
                            <v-date-picker class="date-picker" v-model="date.range" @click:date="onStartDateChange"
                                :max="new Date().toISOString()" header-color="secondary" color="pink darken-2" range>
                            </v-date-picker>
                        </div>
                        <div class="text-center purple pt-2">
                            <span class="offwhite--text">End Date</span>
                            <v-date-picker class="date-picker" v-model="date.range" @click:date="onEndDateChange"
                                :max="new Date().toISOString()" header-color="secondary" color="pink darken-2" range>
                            </v-date-picker>
                        </div>
                    </template>
                    <template v-else>
                        <v-date-picker class="date-picker" v-model="date.range" @change="onDateChange" :max="new Date().toISOString()"
                            header-color="secondary" color="pink darken-2" range>
                        </v-date-picker>
                    </template>
                    <div class="shortcuts d-none d-sm-block">
                        <div class="shortcuts-header secondary"></div>
                        <v-list class="d-flex flex-column justify-space-around dates-list" color="purple">
                            <v-list-item @click="setDate('7')" :class="duration === '7' && 'pink'">
                                <v-list-item-content>
                                    <div class="text-center">Last 7 Days</div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :class="duration === '30' && 'pink'" @click="setDate('30')">
                                <v-list-item-content>
                                    <div class="text-center">
                                        Last 30 Days
                                    </div>

                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :class="duration === 'current' && 'pink'" @click="setDate('current')">
                                <v-list-item-content>
                                    <div class="text-center">{{ currentMonth() }} {{ new Date().getFullYear() }}</div>

                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :class="duration === '1' && 'pink'" @click="setDate('1')">
                                <v-list-item-content>
                                    <div class="text-center">{{ lastMonth() }} {{
                                        new
                                                                            Date().getFullYear()
                                    }}</div>

                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :class="duration === 'year' && 'pink'" @click="setDate('year')">
                                <v-list-item-content>
                                    <div class="text-center">Last Year</div>

                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </div>
            </v-card>
        </template>
    </v-menu>
</template>

<script>
import moment from 'moment';
import { reactive, ref, onMounted, computed } from '@vue/composition-api';
import { parseDate } from '../../utils/utils';
import { mdiCalendar } from '@mdi/js';

export default {
    props: {
        range: Array,
        span: String,
        block: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const duration = ref('30');

        const lastStart = ref('');
        const lastEnd = ref('');

        const date = reactive({ range: [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')] })


        const currentMonth = () => {
            return moment().startOf("month").format('MMMM')
        }

        const lastMonth = () => {
            return moment().subtract(1, "month").startOf("month").format('MMMM')
        }

        const lastTwoMonths = () => {
            return moment().subtract(2, "month").startOf("month").format('MMMM')
        }

        const onDateChange = (value) => {
            date.range[0] = value[0]
            date.range[1] = value[1]

            lastStart.value = value[0];
            lastEnd.value = value[1];
            // date.range.push(...value)

            // Emit Date Change Event
            emit('onDateRangeChange', date.range)
        }

        const onStartDateChange = (value) => {
            date.range[0] = value

            lastStart.value = value;

            if (lastEnd.value) {
                date.range[1] = lastEnd.value
            }

            if (date.range[1]) {
                emit('onDateRangeChange', date.range)
            }
        }

        const onEndDateChange = (value) => {
            date.range[1] = value

            lastEnd.value = value

            if (lastStart.value) {
                date.range[0] = lastStart.value
            }

            if (date.range[0]) {
                emit('onDateRangeChange', date.range)
            }
        }

        const setDate = (value) => {
            duration.value = value

            if (value === '7') {
                // Set Last 7 Days
                date.range[0] = moment().subtract(7, 'days').format('YYYY-MM-DD')
                date.range[1] = moment().format('YYYY-MM-DD')

            }
            if (value === '30') {
                // Set Last 30 Days
                date.range[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
                date.range[1] = moment().format('YYYY-MM-DD')
            }
            if (value === 'current') {
                // Set Current Month
                const month = moment().month() + 1
                const year = moment().year()
                date.range[0] = moment(`${year}-${month}-01`).format('YYYY-MM-DD')
                date.range[1] = moment().format('YYYY-MM-DD')
            }
            if (value === '1') {
                // Set Last Month
                const month = moment().month()
                const year = moment().year()
                const days = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()

                date.range[0] = moment(`${year}-${month}-01`).format('YYYY-MM-DD')
                date.range[1] = moment(`${year}-${month}-${days}`).format('YYYY-MM-DD')

            }
            if (value === '2') {
                // Set Last 2 Months
                const month = moment().month() - 1
                const year = moment().year()
                const days = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()

                date.range[0] = moment(`${year}-${month}-01`).format('YYYY-MM-DD')
                date.range[1] = moment(`${year}-${month}-${days}`).format('YYYY-MM-DD')
            }

            if (value === 'year') {
                // Set Last Year
                date.range[0] = moment().subtract(1, 'years').format('YYYY-MM-DD')
                date.range[1] = moment().format('YYYY-MM-DD')
            }

            lastStart.value = date.range[0]
            lastEnd.value = date.range[1]

            // Emit Date Change Event
            emit('onDateRangeChange', date.range)
        }

        onMounted(() => {
            const range = props && props.range
            const span = props && props.span ? props.span : '30'

            duration.value = span
            if (range && Array.isArray(range)) {
                date.range = [...range]
            }
        })

        return {
            calendar: mdiCalendar,
            date,
            duration,
            parseDate,
            onDateChange,
            onStartDateChange,
            onEndDateChange,
            currentMonth,
            lastMonth,
            lastTwoMonths,
            setDate
        }
    }
}
</script>


<style scoped>
.dates-list {
    border-left: 1px solid var(--v-boldPurple-base) !important;
    padding: 15px;
}
</style>